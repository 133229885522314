// theme.scss

.masthead {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  //margin-block-end: 1rem;
  padding-block: 1rem;
  .logo {
    width: 8rem;
  }
  .tagline {
    //color: $gray-500;
    //font-family: $font-family-serif;
    text-align: center;
  }
}

.hero {
  background: transparent url(../img/After-the-Rains.jpg) no-repeat left center;
  background-size: cover;
  border-radius: 0.5rem;
  height: 20rem;
  margin-block-end: 1rem;
  @include media-breakpoint-up(lg) {
    height: 30rem;
  }
}

.prose {
  max-width: 80ch;
  margin-inline: auto;
}
